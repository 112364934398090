import SEO from "../components/seo";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import "../assets/css/main.css";

const ContactPage = () => (
  <Layout>
    <SEO
      seo={{
        metaTitle: "contact",
        metaDescription: "お問い合わせフォーム",
      }}
    />
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSdq-tgX9RHS8p4jZAZwovDX89ftpayXnvHvYd_C8Kkw7JRX5A/viewform?embedded=true"
      width="640"
      height="1096"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
    ></iframe>
  </Layout>
);

export default ContactPage;
